import React, { useState } from 'react';
import './Accordion.css'
import styles from "./Home.module.css"
import TextIcn from "../../Images/textIcn.png"
import SecBtnIcn from "../../Images/secBtnIcn.png"
import Box1 from "../../Images/growicon1.png"
import Box2 from "../../Images/growicon2.png"
import FourthImg from "../../Images/homegrow.png"
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { IoMdCheckmark } from "react-icons/io";
import { FaAngleUp } from "react-icons/fa6";
const Home = () => {
    const [isOpen, setIsOpen] = useState(true);
    const [isOpen1, setIsOpen1] = useState(true);
    const [isOpen2, setIsOpen2] = useState(true);
    const [isOpen3, setIsOpen3] = useState(true);
    const [isOpen4, setIsOpen4] = useState(true);
    const [isOpen5, setIsOpen5] = useState(true);
    const [isOpen6, setIsOpen6] = useState(true);
    const [isOpen7, setIsOpen7] = useState(true);

  return (
    <div className={styles.homeMain}>
  
  <Header/>
   
{/* First Section */}

    <div className={styles.homeBgMain} >
      <div className={styles.mainHeading}>
    <span>For those</span>    
    <br />
    who think about money differently
      </div>
    </div>

{/* Second Section  */}
<section id='action2'>
<div className={styles.secondMain}>
    <div className={styles.secondLeftMain}>
        <div className={styles.secondHeading}>
        Wisdom is knowing what to do next. <span>Skill is knowing how to do it.</span> Virtue is doing it.<span className={styles.textIconmain}>
            <img src={TextIcn} alt="" className={styles.textIcn} />
        </span>
       
        </div>
        <div className={styles.secondText}>- Thomas Jefferson</div>
    </div>
    <div className={styles.secondRightMain}>
        <div className={styles.secRightText}>
        We serve clients who think about money differently. For those who never stop learning, who live life for a deeper purpose, and have a generous heart toward others.
        </div>
        <div className={styles.secRightbutton}>
       <button className={styles.secBtn}>Request a meeting 
        <img src={SecBtnIcn} alt="" className={styles.secBtnIcn} />
       </button>
        </div>
    </div>
</div>
</section>


{/* Third Section  */}

<div className={styles.ThirdMain}>
    <div className={styles.ThirdCont1}>
        <div className={styles.thirdSec1Main}>
            <div className={styles.thirdSec1Heading}>
            Vision
            </div>
            <div className={styles.thirdSec1Text}>
            The planning process starts with your values and leads into your desired outcomes.  What do you want to accompish?
            </div>
        </div>
        <div className={styles.thirdSec2Main}>
            <div className={styles.thirdSec1Heading}>
            Build
            </div>
            <div className={styles.thirdSec1Text}>
            Putting a team around you helps to ensure the right experts are in the right seats.  This team serves the plan and you. This can include estate planning attorneys, CPA's, insurance professionals, and more.
            </div>
        </div>
        <div className={styles.thirdSec1Main}>
            <div className={styles.thirdSec1Heading}>
            Plan
            </div>
            <div className={styles.thirdSec1Text}>
            Constructing a financial plan to fit and meet your desired outcomes. This is where the numbers come into play. The plan creates a basis to help make decisions moving forward
            </div>
        </div>
    </div>
    <div className={styles.ThirdCont2}>
      
        <div className={styles.thirdSec2Main}>
            <div className={styles.thirdSec1Heading}>
            Measure & Monitor
            </div>
            <div className={styles.thirdSec1Text}>
            Plans are never complete, because life is always changing and unpredictable.  We build margin into the plan for the unexpected and continuously measure where you are.
            </div>
        </div>
        <div className={styles.thirdSec1Main}>
            <div className={styles.thirdSec1Heading}>
            Adapt
            </div>
            <div className={styles.thirdSec1Text}>
            If nots "if" we get off track, its "when".  Its important to know how to adapt and get back on track.
            </div>
        </div>
    </div>
</div>

{/* Fourth Section  */}

<div className={styles.fourthMain}>
<div className={styles.fourthCont}>
    <div className={styles.fourthLeftMain}>
        <div className={styles.HeadingButton}>
            <button className={styles.LeftheadingBtn}>Our Values</button>
        </div>
        <div className={styles.fourthLeftHeading}>
        People
        </div>
        <div className={styles.fourthLeftText}>
        At Solomon Wealth Management, we believe in the power of personal connection and the importance of understanding each individual's unique story. Our approach to financial planning is not about pushing products or just managing assets; it's about creating a plan that reflects who you are and what you value most.
        </div>
    </div>
    <div className={styles.fourthRightMain}>
    <div className={styles.fourthRightCont}>
        <div className={styles.fourthBox1Main}>
            <div className={styles.fourthBoxIconMain}>
                <img src={Box1} alt="" className={styles.boxIcon} />
            </div>
            <div className={styles.boxIconHeading}>
            TO OFFER
            <br /> 
            COMPREHENSIVE
            <br />
            SOLUTIONS
            </div>

        </div>
        <div className={styles.fourthBox2Main}>
            <div className={styles.fourthBoxIconMain}>
                <img src={Box2} alt="" className={styles.boxIcon} />
            </div>
            <div className={styles.boxIconHeading2}>
            WE HELP YOU TO GROW
            </div>

        </div>
        <img src={FourthImg} alt="" className={styles.fourthImg} />
    </div>
    </div>
</div>
</div>



{/* Fifth Section  */}


<div className={styles.FifthMain}>
    <div className={styles.fifthMainHeading}>
    What We Offer
    </div>
    <div className={styles.AccordionMainCont}>
    <div className={styles.fifthAccordianMain}>
    <div className="icon-circle">
          <div >
          <IoMdCheckmark size={35} className="check-icon"/>
          </div>
        </div>
    <div className="accordion">
      <div className="accordion-header" onClick={() => setIsOpen(!isOpen)}>
       
        <div className='accordionheading'>Financial Planning</div>
        <span className={`arrow ${isOpen ? 'open' : ''}`}><FaAngleUp /></span>
      </div>
      <div className="headerunderline"></div>
      {isOpen && (
        <div className="accordion-content">
          <p>A comprehensive financial plan tailored to the individual and family goals and values for each phase of life.</p>
        </div>
      )}
    </div>
    </div>
    <div className={styles.fifthAccordianMain}>
    <div className="icon-circle">
          <div >
          <IoMdCheckmark size={35} className="check-icon"/>
          </div>
        </div>
    <div className="accordion">
      <div className="accordion-header" onClick={() => setIsOpen1(!isOpen1)}>
       
        <div className='accordionheading'>Investments</div>
        <span className={`arrow ${isOpen1 ? 'open' : ''}`}><FaAngleUp /></span>
      </div>
      <div className="headerunderline"></div>
      {isOpen1 && (
        <div className="accordion-content">
          <p>Managing individual portfolios to meet client investment objectives. Preservation and tax-efficient solutions.</p>
        </div>
      )}
    </div>
    </div>
    <div className={styles.fifthAccordianMain}>
    <div className="icon-circle">
          <div >
          <IoMdCheckmark size={35} className="check-icon"/>
          </div>
        </div>
    <div className="accordion">
      <div className="accordion-header" onClick={() => setIsOpen2(!isOpen2)}>
       
        <div className='accordionheading'>Tax Planning</div>
        <span className={`arrow ${isOpen2 ? 'open' : ''}`}><FaAngleUp /></span>
      </div>
      <div className="headerunderline"></div>
      {isOpen2 && (
        <div className="accordion-content">
          <p>Coordinating the planning needed for tax estimates, and at times, working with CPA's to ensure tax efficiency in the plan.</p>
        </div>
      )}
    </div>
    </div>
    <div className={styles.fifthAccordianMain}>
    <div className="icon-circle">
          <div >
          <IoMdCheckmark size={35} className="check-icon"/>
          </div>
        </div>
    <div className="accordion">
      <div className="accordion-header" onClick={() => setIsOpen3(!isOpen3)}>
       
        <div className='accordionheading'>Giving & Impact Investing</div>
        <span className={`arrow ${isOpen3 ? 'open' : ''}`}><FaAngleUp /></span>
      </div>
      <div className="headerunderline"></div>
      {isOpen3 && (
        <div className="accordion-content">
          <p>Charitable strategies such as donor-advised funds and equity gifts. Portfolio screening to align your values with your investments.</p>
        </div>
      )}
    </div>
    </div>
    <div className={styles.fifthAccordianMain}>
    <div className="icon-circle">
          <div >
          <IoMdCheckmark size={35} className="check-icon"/>
          </div>
        </div>
    <div className="accordion">
      <div className="accordion-header" onClick={() => setIsOpen4(!isOpen4)}>
       
        <div className='accordionheading'>LPL Financial</div>
        <span className={`arrow ${isOpen4 ? 'open' : ''}`}><FaAngleUp /></span>
      </div>
      <div className="headerunderline"></div>
      {isOpen4 && (
        <div className="accordion-content">
          <p>24-hour account service for any needs. Simple and easy platform to view accounts online and access account information.</p>
        </div>
      )}
    </div>
    </div>
    <div className={styles.fifthAccordianMain}>
    <div className="icon-circle">
          <div >
          <IoMdCheckmark size={35} className="check-icon"/>
          </div>
        </div>
    <div className="accordion">
      <div className="accordion-header" onClick={() => setIsOpen5(!isOpen5)}>
       
        <div className='accordionheading'>Insurance</div>
        <span className={`arrow ${isOpen5 ? 'open' : ''}`}><FaAngleUp /></span>
      </div>
      <div className="headerunderline"></div>
      {isOpen5 && (
        <div className="accordion-content">
          <p>Risk management plays a vital role in financial planning.  Making sure we protect what you have built and the ones you love.</p>
        </div>
      )}
    </div>
    </div>
    <div className={styles.fifthAccordianMain}>
    <div className="icon-circle">
          <div >
          <IoMdCheckmark size={35} className="check-icon"/>
          </div>
        </div>
    <div className="accordion">
      <div className="accordion-header" onClick={() => setIsOpen6(!isOpen6)}>
       
        <div className='accordionheading'>eMoney</div>
        <span className={`arrow ${isOpen6 ? 'open' : ''}`}><FaAngleUp /></span>
      </div>
      <div className="headerunderline"></div>
      {isOpen6 && (
        <div className="accordion-content">
          <p>This client website is the central hub for your financial plan and financial life. Putting everything in one place and keeping things simple and organized.</p>
        </div>
      )}
    </div>
    </div>
    <div className={styles.fifthAccordianMain}>
    <div className="icon-circle">
          <div >
          <IoMdCheckmark size={35} className="check-icon"/>
          </div>
        </div>
    <div className="accordion">
      <div className="accordion-header" onClick={() => setIsOpen7(!isOpen7)}>
       
        <div className='accordionheading'>Estate Planning</div>
        <span className={`arrow ${isOpen7 ? 'open' : ''}`}><FaAngleUp /></span>
      </div>
      <div className="headerunderline"></div>
      {isOpen7 && (
        <div className="accordion-content">
          <p>Legacy Planning, Wealth transfer strategies, next-generation counsel
          Coordinating with your estate planning attorney to implement the essential financial, charitable, and estate planning tools.</p>
        </div>
      )}
    </div>
    </div>
</div>
</div> 



<Footer/>
    </div>
  )
}

export default Home
