import React from 'react'
import styles from "./Header2.module.css"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Logo from "../../Images/aboutlogo.png"
import Logo2 from "../../Images/logo.png"
import BtnIcn from "../../Images/btnicn.png"
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const Header2 = () => {
  const navigate = useNavigate();
  return (
    <>
      {['lg'].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3">
          <Container fluid>
            <Navbar.Brand href="/">
                <img src={Logo} alt="" className={styles.logoImg} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <img src={Logo2} alt="" className={styles.logoImg} />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                <Link to="/about">
                 <div   className={styles.navLink} >About us</div>
                 </Link>
                </Nav> 
                <button className={styles.headerButton}
                  onClick={() => navigate('//wealth.emaplan.com/ema/lpl/solomonwm ')}
                >eMoney Log In 
                    <img src={BtnIcn} alt="" className={styles.btnIcn} />
                </button>
              
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  )
}

export default Header2
